import React, { useState, useEffect, useRef } from "react";
import "./Book.css";
import { Box, IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import HTMLFlipBook from "react-pageflip";

function Flipbook({ firstEdition_img, setActiveImg, activeimg }) {
  const flipBookRef = useRef();
  const [currentPage, setCurrentPage] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bookWidth, setBookWidth] = useState(
    window.innerWidth > 768 ? 350 : window.innerWidth * 0.9
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setBookWidth(window.innerWidth > 768 ? 350 : window.innerWidth * 0.9);
  };

  const goToPreviousPage = () => {
    flipBookRef.current.pageFlip().flipPrev();
    const newPage = flipBookRef.current.pageFlip().getCurrentPageIndex();

    if (
      currentPage === 0 ||
      currentPage.data === 1 ||
      newPage == 0 ||
      newPage == 1
    ) {
      setActiveImg(false);
    } else {
      setActiveImg(true);
    }
  };

  const goToNextPage = () => {
    flipBookRef.current.pageFlip().flipNext();
    setActiveImg(true);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageClick = (event, value) => {
    setActiveImg(true);
  };

  return (
    <>
      <Box className="mainFlipbookDiv">
        <HTMLFlipBook
          width={bookWidth}
          height={windowWidth > 768 ? 350 : windowWidth * 0.9}
          showCover={true}
          flippingTime={1000}
          maxShadowOpacity={0.5}
          className={!activeimg ? "album1 stf__parent" : "album"}
          page={currentPage}
          onFlip={(newPage) => setCurrentPage(newPage)}
          ref={flipBookRef}
        >
          {firstEdition_img &&
            firstEdition_img?.map((img, index) => {
              return (
                <img
                  src={img?.image}
                  alt="img"
                  onClick={() => handleImageClick(index)}
                />
              );
            })}
        </HTMLFlipBook>
      </Box>
      <Box className="bookButton">
        <Box
          style={
            activeimg
              ? {
                  marginTop: "10px !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }
              : {
                  marginTop: "10px !important",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }
          }
        >
          <IconButton
            style={{ background: "#0192be", padding: "8px" }}
            onClick={goToPreviousPage}
          >
            <ChevronLeftIcon style={{ color: "#fff" }} />
          </IconButton>
          <IconButton
            style={{ background: "#0192be", padding: "8px" }}
            onClick={goToNextPage}
          >
            <ChevronRightIcon style={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default Flipbook;
