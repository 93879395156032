import React, { forwardRef } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Card,
  Avatar,
  Dialog,
  IconButton,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CropHealthFlipBook from "./Crophealth/CropHealthFlipBook";
import Sitemap from "./sitemap/Sitemap";
import { localeFunction } from "../utils";
import Acrchieve from "./SujalamSufalam/Acrchieve";
import Allannoucement from "./Allannoucement";

const useStyles = makeStyles((theme) => ({
  MainHero_Section: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card_Heading: {
    display: "flex",
    padding: "6px 0px 6px 15px",
    alignItems: "center",
    position: "relative",
    height: "55px",
    gap: "15px",
    width: "100%",
    justifyContent: "center",
    borderRadius: "5px 5px 0px 0px",
    "@media(max-width:425px)": {
      padding: "14px 0px 15px 14px",
      display: "flex",
      justifyContent: "start",
      gap: "8px",
    },
    background: "#0192be",
    "& .dialog_header": {
      display: "flex",
      // padding: "6px 0px 6px 15px",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      gap: "15px",
    },

    "& .closeicon": {
      textAlign: "end",
      // width: "100%",
      color: "#FFF",
      marginRight: "10px",
      zIndex: 999,
      cursor: "pointer",
    },
    "& h6": {
      fontWeight: 500,
      color: "#FFF",
      // whiteSpace: "pre",
    },
    "& .iconbox": {
      width: "40px",
      height: "40px",
      color: "#FFF",
      background: "rgb(3, 125, 162)",
    },
  },
  mainCard_section: {
    background: "#f4f4f4",
    "& .iconbox": {
      width: "35px",
      height: "35px",
      color: "#FFF",
      background: "#06485b",
    },
    "& .mainCard": {
      borderRadius: "0px !important",
    },
    "& .list_card": {
      display: "flex",
      gap: "10px",
      // padding: "6px 0px 5px 15px",
      padding: "10px 5px 5px 15px",
      alignItems: "center",
      cursor: "pointer !important",
    },
  },
  main_herosection: {
    marginLeft: "42px",
    marginRight: "42px",
    // // height: "532px",
    // marginTop: "40px",
    "& .conaitnerbox": {
      marginLeft: "24px",
      marginRight: "24px",
      "@media(max-width:1280px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    "@media(max-width:1280px)": {
      // height: "455px",
      marginTop: "15px",
      // marginLeft: "20px",
      // marginRight: "16px",
    },
    "@media(max-width:425px)": {
      height: "auto",
      marginTop: "15px",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  ViewMoreBtn: {
    textAlign: "end",
    color: "#06485b",
    width: "100%",
    bottom: "0px",
    right: "0px",
    // position: "absolute",
  },
  cardIcon: {
    width: "35px",
    height: "35px",
    color: "#FFF",
    background: "#06485b",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "60%",
      height: "60%",
    },
    "& h6": {
      cursor: "pointer !important",
    },
  },

  cardIconAnnouc: {
    width: "40px",
    height: "40px",
    background: "#FFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginLeft: "175px",
    "@media(max-width:426px)": {
      // marginLeft: "85px",
    },
    "& img": {
      width: "60%",
      height: "60%",
    },
  },
  card_heading_icon: {
    width: "40px",
    height: "40px",
    background: "#FFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "60%",
      height: "60%",
      paddingTop: "5px",
    },
  },
  paper: {
    minWidth: "1100px",
    paddingBottom: "10px",
  },
  appBar: {
    background: "#0192be",
  },
  acrchiveHeading: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    "& h6": {
      fontSize: "48px",
      fontWeight: 600,
      color: "#0192be",
    },
  },
  closeIcon: {
    position: "absolute",
    zIndex: 1,
    top: "0px",
    right: "30px",
    // "@media(max-width:1280px)": {},
  },
  pdficon: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  searchCompo: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    marginRight: "80px",
    marginTop: "9px",
  },
  annoucePoPBox: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 20,
    "@media(max-width:425px)": {
      top: 3,
    },
  },
}));
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TopSwapperDialog = ({
  swapperClose,
  openSwappeer,
  annoucement,
  type,
  acrchive,
  CROP_HEALTH,
  swapperOpen,
  setAcriveListData1,
  searchQuery,
  _count,
  _count1,
  page,
  setPage,
  page1,
  setPage1,
  filteredData,
  _searcgData,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Dialog
        open={openSwappeer}
        TransitionComponent={Transition}
        fullWidth={true}
        fullScreen
      >
        {filteredData ? (
          <Box>
            <Box
              className={classes.card_Heading}
              style={{ borderRadius: "0px" }}
            >
              {type == "CropHealthBulletin" ? (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6">
                    {" "}
                    {localeFunction("CROP_HEALTH_BULLETIN")}
                  </Typography>
                </Box>
              ) : (
                <>
                  {" "}
                  <Avatar
                    alt="Remy Sharp"
                    style={{ background: "white" }}
                    src="/images/hero section icon/Announcements-Blue.png"
                  />
                  <Typography variant="h6">
                    {localeFunction("Announcements")}
                  </Typography>
                </>
              )}
            </Box>
            <Box className={classes.annoucePoPBox}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={swapperClose}
                aria-label="close"
              >
                <CloseIcon style={{ color: "#FFF" }} />
              </IconButton>
            </Box>
            <Allannoucement
              page1={page1}
              setPage1={setPage1}
              setPage={setPage}
              _count1={_count1}
              _count={_count}
              filteredData={filteredData}
              _searcgData={_searcgData}
              annoucement={annoucement}
              searchQuery={searchQuery}
            />
          </Box>
        ) : (
          <>
            {type === "sitemap" ? (
              <>
                <Box
                  className={classes.card_Heading}
                  style={{
                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6">
                    {localeFunction("Sitemap")}
                  </Typography>
                </Box>
                <Box className={classes.closeIcon}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={swapperClose}
                    aria-label="close"
                  >
                    <CloseIcon style={{ color: "#FFF" }} />
                  </IconButton>
                </Box>
                <Box mt={2}>
                  <Container>
                    <Sitemap swapperOpen={swapperOpen} />
                  </Container>
                </Box>
              </>
            ) : (
              <Box>
                {acrchive === "acrchive" ? (
                  <>
                    <Box
                      className={classes.card_Heading}
                      style={{
                        borderRadius: "0px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box className={classes.cardIconAnnouc}>
                        <img src="/images/archive.png" alt="img" />
                      </Box>
                      <Typography variant="h6">
                        {localeFunction("ARCHIVES")}
                      </Typography>
                    </Box>
                    <Box className={classes.closeIcon}>
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          swapperClose();
                          setAcriveListData1(0);
                        }}
                        aria-label="close"
                      >
                        <CloseIcon style={{ color: "#FFF" }} />
                      </IconButton>
                    </Box>

                    <Card elevation={0}>
                      <Box className="acrchiveCard" id="style-9">
                        <Acrchieve CROP_HEALTH={CROP_HEALTH} />
                      </Box>
                    </Card>
                  </>
                ) : (
                  <Box>
                    <Box
                      className={classes.card_Heading}
                      style={{ borderRadius: "0px" }}
                    >
                      {type === "CropHealthBulletin" ? (
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h6">
                            {" "}
                            {localeFunction("CROP_HEALTH_BULLETIN")}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          {" "}
                          <Avatar
                            alt="Remy Sharp"
                            style={{ background: "white" }}
                            src="/images/hero section icon/Announcements-Blue.png"
                          />
                          <Typography variant="h6">
                            {localeFunction("Announcements")}
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        top: 0,
                        right: 20,
                      }}
                    >
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={swapperClose}
                        aria-label="close"
                      >
                        <CloseIcon style={{ color: "#FFF" }} />
                      </IconButton>
                    </Box>

                    {type === "CropHealthBulletin" ? (
                      <>
                        <CropHealthFlipBook />
                      </>
                    ) : (
                      <Box>
                        <Allannoucement
                          annoucement={annoucement}
                          searchQuery={searchQuery}
                          page={page}
                          setPage={setPage}
                          setPage1={setPage1}
                          _count={_count}
                          _count1={_count1}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default TopSwapperDialog;
