import React from "react";
import {
  Box,
  Typography,
  Container,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@material-ui/core";
import { localeFunction } from "../../utils";

const useStyles = makeStyles((theme) => ({
  cardheader: {
    background: "#0192be21",
    borderRadius: "5px 5px 0 0",
    border: "1px solid #ddd",
    fontSize: "12px !important",
  },
  root: {
    height: "100%",
    width: "100%",
    "& a": {
      textDecoration: "none",
      fontSize: "16px",
      fontWeight: "500",
      color: "#000",
      textAlign: "justify",
      fontFamily: "system-ui",
    },
    "& h2": {
      fontSize: "16px",
      lineHeight: "1.2",
      fontWeight: "500",
      color: "#000",
      fontFamily: "system-ui",
    },
    "& h3": {
      fontSize: "18px",
      fontWeight: "500",
      textAlign: "center",
      color: "#000",
      fontFamily: "system-ui",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  mainList: {
    listStyle: "none",
    "& h3": {
      fontWeight: 600,
      textTransform: "Capitalize",
      fontSize: "20px",
    },
  },

  submenu: {
    "& h6": {
      paddingTop: "5px",
      fontSize: "16px",
      textTransform: "Capitalize",
    },
  },
  underlineText: {
    cursor: "pointer",
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
  },
}));

const Sitemap = ({ swapperOpen }) => {
  const classes = useStyles();
  // const [openSwappeer, setOpenSwapper] = useState(false);
  // const [sitetype, setSitetype] = useState("");

  // const swapperOpen = (type) => {
  //   setOpenSwapper(true);
  //   // setSitetype(type);
  // };

  // const swapperClose = () => {
  //   setOpenSwapper(false);
  // };

  const home = [
    {
      title: `${localeFunction("My_Applications")}`,
      link: "/",
    },
    {
      title: `${localeFunction("Announcements")}`,
      link: "/",
    },
    {
      title: `${localeFunction("Quick_Links")}`,
      link: "/",
    },
    {
      title: `${localeFunction("Moments")}`,
      link: "/",
    },
  ];

  const OurPortal = [
    {
      title: `${localeFunction("External_Portal_(website)")}`,
      link: "https://sarus-elb-345957920.ap-south-1.elb.amazonaws.com:8080/",
      subtitle: "CropHealthBulletin",
    },

    {
      title: `${localeFunction("KMS Portal")}`,
      link: "https://kms.aicofindia.com/",
    },
    {
      title: `${localeFunction("Farmer Portal")}`,
      link: "https://portal.aicofindia.com/FP/",
    },

    {
      title: `${localeFunction("Field Investigation")}`,
      link: "https://portal.aicofindia.com/FIP/",
    },
    {
      title: `${localeFunction("Channel Partner")}`,
      link: "https://portal.aicofindia.com/CPP/",
    },
  ];

  const Publications = [
    {
      title: `${localeFunction("Crop Health Bulletin")}`,
      link: "/",
    },
  ];

  // const Help = [
  //   {
  //     title: `${localeFunction("Help")}`,
  //     link: "/",
  //   },
  // ];

  return (
    <Container>
      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {localeFunction("Home")}
                  </Typography>
                }
                className={classes.cardheader}
              />

              <CardContent>
                {home &&
                  home?.map((data, index) => {
                    return (
                      <Box mb={1} key={index} pl={2}>
                        <a href={data?.link} className={classes.underlineText}>
                          <Typography variant="h6">{data?.title}</Typography>
                        </a>
                      </Box>
                    );
                  })}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {localeFunction("Our_Portals")}
                  </Typography>
                }
                className={classes.cardheader}
              />

              <CardContent>
                {OurPortal &&
                  OurPortal?.map((data, index) => {
                    return (
                      <Box mb={1} key={index}>
                        <a
                          href={data?.link}
                          target="_blank"
                          className={classes.underlineText}
                        >
                          <Typography variant="h6">{data?.title}</Typography>
                        </a>
                      </Box>
                    );
                  })}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card className={classes.root}>
              <CardHeader
                title={
                  <Typography
                    variant="h3"
                    style={{ textTransform: "capitalize" }}
                  >
                    {localeFunction("publications")}
                  </Typography>
                }
                className={classes.cardheader}
              />

              <CardContent>
                {Publications &&
                  Publications?.map((data, index) => {
                    return (
                      <Box mb={1} key={index}>
                        <a className={classes.underlineText}>
                          <Typography
                            variant="h6"
                            onClick={() => swapperOpen("CropHealthBulletin")}
                          >
                            {data?.title}
                          </Typography>
                        </a>
                      </Box>
                    );
                  })}
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card className={classes.root}>
              <CardHeader
                title={
                  <Typography variant="h3">{localeFunction("Help")}</Typography>
                }
                className={classes.cardheader}
              />
              <CardContent>
                {Help &&
                  Help?.map((data, index) => {
                    return (
                      <Box mb={1} key={index}>
                        <a href={data?.link}>
                          <Typography variant="h6">{data?.title}</Typography>
                        </a>
                      </Box>
                    );
                  })}
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Box>
    </Container>
  );
};

export default Sitemap;
