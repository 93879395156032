import React, { useEffect, useState } from "react";

import {
  Box,
  makeStyles,
  Container,
  Typography,
  IconButton,
} from "@material-ui/core";
import Book from "./Book";
import TopSwapperDialog from "../TopSwapperDialog";
import Apiconfigs from "../../ApiConfig/Apiconfig";
import { getAPIdata, localeFunction } from "../../utils";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tooltip from "@material-ui/core/Tooltip";
import CrophelthButtenTable from "./CrophelthButtenTable";

const useStyles = makeStyles((theme) => ({
  mainFlipbookBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflowX: "hidden",
    "@media(max-width:1280px)": {
      marginTop: "20px",
    },
    "& .archive": {
      maxWidth: "90px",
      minWidth: "90px",
      position: "absolute",
      cursor: "pointer",
      right: 10,
      top: "5%",
      "@media(max-width:599px)": {
        top: "2%",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
}));

const CropHealthFlipBook = () => {
  const classes = useStyles();
  const [activeimg, setActiveImg] = useState(false);
  const [openSwappeer, setOpenSwapper] = useState(false);
  const [_crophelpdfthbook, setCrophelthbook] = useState("");
  const [_archiveValue, setArchiveValue] = useState(0);
  const [_archivelistdata, setAcriveListData] = useState("");
  const [_crophelthdoc, setCrophelthDoc] = useState("");

  const swapperOpen = () => {
    setOpenSwapper(true);
  };

  const swapperClose = () => {
    setOpenSwapper(false);
  };

  const archiveImgFunc = () => {
    setArchiveValue(1);
  };

  const _crophelth = async () => {
    try {
      const res = await getAPIdata({
        endPoint: Apiconfigs.crop_health + `/${_archiveValue}`,
      });
      if (res) {
        setCrophelthbook(res?.result);
        setAcriveListData(res?.result);
        setCrophelthDoc(res?.doc);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (_archiveValue == 0 || _archiveValue) {
      _crophelth();
    }
  }, [_archiveValue]);

  return (
    <Box className={classes.mainFlipbookBox}>
      <Container>
        <Book
          firstEdition_img={_crophelpdfthbook}
          setActiveImg={setActiveImg}
          activeimg={activeimg}
        />
        <Box textAlign={"center"} mt={4}>
          <Container>
            <CrophelthButtenTable _crophelthdoc={_crophelthdoc} />
          </Container>
        </Box>
      </Container>

      <Box className="archive" onClick={swapperOpen}>
        <img src="/images/arc.png" alt="archiveimg" onClick={archiveImgFunc} />
      </Box>
      <TopSwapperDialog
        openSwappeer={openSwappeer}
        swapperOpen={swapperOpen}
        swapperClose={swapperClose}
        acrchive={"acrchive"}
        CROP_HEALTH={_archivelistdata}
        setAcriveListData1={setArchiveValue}
      />
    </Box>
  );
};

export default CropHealthFlipBook;
