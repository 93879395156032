import React, { useContext } from "react";
import { Box, Typography, makeStyles, IconButton } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory } from "react-router-dom";
import { localeFunction } from "../../utilskms";
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  topbarContaier: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    left: 0,
    right: 0,
    height: "69px",
    padding: "6px 25px 6px 0px",
    "@media(max-width:1440px)": {
      padding: "6px 25px 6px 0px",
    },
    "@media(max-width:1280px)": {
      padding: "6px 0px 6px 0px",
    },
    "@media(max-width:320px)": {
      overflowX: "scroll",
      padding: "8px 0px 6px 14px",
    },
    "@media(max-width:425px)": {
      padding: "8px 0px 6px 15px",
    },
    "@media(max-width:768px)": {
      padding: "14px 0px 6px 15px",
    },
  },
  mainIndexsection: {
    "& .menulist": {
      "& h6": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFF",
        textTransform: "captalize",
        whiteSpace: "pre",
        transition: "0.5s ease-in-out",
      },
      "& :hover": {
        color: "#000 !important",
        transition: "0.5s ease-in-out",
      },
    },

    "& .menulist1": {
      "& h6": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#FFF",
        textTransform: "captalize",
        whiteSpace: "pre",
        transition: "0.5s ease-in-out",
      },
      "& :hover": {
        color: "#FFF !important",
        transition: "0.5s ease-in-out",
      },
    },
    "& .menusection": {
      "& .MenuTab": {
        position: "relative",
        "& a": {
          textDecoration: "none",
        },
        "& .menuComponent": {
          display: "none",
          zIndex: 1,
          transition: "0.5s ease-in-out",
        },
        "&:hover": {
          "& .menuComponent": {
            display: "block",
            position: "absolute",
            zIndex: 999,
            background: "#fff",
            width: "250px !important",
            borderBottom: "2px solid #d6952c",
            "@media(max-width:425px)": {
              position: "fixed",
              zIndex: 999,
            },
          },
        },
      },
    },
  },
  mandatesCardWrapper: {
    "& .mandatesTitles": {
      fontWeight: 500,
      padding: "10px 0px 10px 10px",
      fontFamily: "system-ui",
      color: "#000",

      "&:hover": {
        background: "#e4a70a78",
        cursor: "pointer",
      },
    },
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  menuLinks: {
    display: "flex",
    alignItems: "center",
    padding: "0px 50px",
    "@media(max-width:1440px)": {
      padding: "0px 12px",
    },
    "@media(max-width:1280px)": {
      padding: "0px 17px",
    },
    "@media(max-width:1275px)": {
      padding: "0px 2px",
    },
  },
  text: {
    color: "#FFF",
  },
}));

const MainAppbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const handleNavigation = (path) => {
    if (auth && auth.setSelectedMenu) {
      localStorage.removeItem("selectedMenu");
      auth.setSelectedMenu("");
    }

    history.push(path);
  };

  return (
    <Box className={classes.mainIndexsection}>
      <Box className="menusection">
        <Box className={classes.topbarContaier}>
          <Box className={classes.menuLinks}>
            <IconButton onClick={() => handleNavigation("/kms-portal")}>
              <HomeIcon style={{ color: "#FFF", cursor: "pointer" }} />
            </IconButton>
          </Box>

          {/* <Box className={classes.menuLinks}>
            <Box className="MenuTab">
              <NavLink to="/my-task">
                <Box className="menulist">
                  <Typography variant="h6">
                    {localeFunction("My_Tasks")}
                  </Typography>
                </Box>
              </NavLink>
            </Box>
          </Box> */}

          {/* <Box className={classes.menuLinks}>
            <Box className="MenuTab">
              <NavLink to="/contact">
                <Box className="menulist">
                  <Typography variant="h6">
                    {localeFunction("Contacts")}
                  </Typography>
                </Box>
              </NavLink>
            </Box>
          </Box> */}

          <Box className={classes.menuLinks}>
            <Box className="MenuTab">
              {/* <NavLink to="/"> */}
              <Box
                className="menulist1"
                style={{
                  background: "#8c5f09",
                  padding: "5px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleNavigation("/")}
              >
                <Typography variant="h6">
                  {localeFunction("internal_Portal")}
                </Typography>
              </Box>
              {/* </NavLink> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainAppbar;
