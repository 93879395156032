import React from "react";

const ProfileImg = () => {
  return (
    <img
      alt="Remy Sharp"
      src="/images/profileicon/placeholder-image.png"
      style={{
        borderRadius: "100%",
        width: "100%",
        height: "100%",
        background: "rgba(255,255,255,0.9)",
      }}
    />
  );
};

export default ProfileImg;
