import React from "react";
import { Dialog, Box, makeStyles, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ProfileImg from "./ProfileImg";

const useStyles = makeStyles((theme) => ({
  modal: {
    // background: "#23262f",
    background: "#fff",
  },

  changgeProfile: {
    padding: "20px 50px",
  },
  ShowProfileImg: {
    width: "200px",
    height: "200px",
    padding: "1px",
    border: "2px solid #ccc",
    borderRadius: "100%",
    zIndex: 999,
    background: "rgba(255,255,255,0.9)",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "100%",
    },
  },
  diaologHeader: {
    textAlign: "end",
    width: "100%",
  },
  Updatebtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnsProfile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginTop: "40px",
  },
}));

export default function AlertDialog({
  open,
  handleClose,
  handleFileChange,
  selectedFile,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.modal }}
    >
      <Box className={classes.diaologHeader}>
        <IconButton
          onClick={handleClose}
          style={{ background: "#0192be", margin: "7px" }}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
      </Box>
      <Box className={classes.changgeProfile}>
        <Box align="center">
          <Box className={classes.ShowProfileImg}>
            {selectedFile ? (
              <img src={URL.createObjectURL(selectedFile)} alt="img" />
            ) : (
              <ProfileImg />
            )}
          </Box>
        </Box>
        <Box className={classes.btnsProfile}>
          <Box className={classes.Updatebtn}>
            <Button variant="outlinedPrimary">
              <label
                htmlFor="imageUpload"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Upload Image
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </label>
            </Button>
          </Box>
          <Box className={classes.Updatebtn}>
            <Button variant="outlinedPrimary">Update</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
